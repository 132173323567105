:root {
  --primary-blue: #082567;
  --textColor-primary: #082567;
  --secondary-pink: #ffb6c1;
}

.header-component {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  max-width: 40rem;
  width: 100%;
  position: relative;
  margin-top: 60px;
}

.header-component h1 {
  font-size: 3.25rem;
  width: min(48rem, 95vw);
  line-height: 3rem;
  margin-bottom: 1.5rem;
  color: var(--textColor-primary);
}

.header-component h2 {
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.75rem; /* 122.222% */
  letter-spacing: -0.72px;
  margin-bottom: 1rem;
}

.header-component p {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 2rem;
}

.header-component a {
  padding: 0.75rem 1.25rem;
  background-color: var(--primary-blue);
  color: white;
  border: none;
  border-radius: 5px;
}

@media all and (max-width: 600px) {
    .header-component h1 {
      font-size: 36px;
      width: auto;
    }
}