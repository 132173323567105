.news-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 7rem;
}

.badge-wrapper-news {
  width: 100%;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  gap: 3rem;
}

.badge-wrapper-news a {
  background-color: white;
  padding: 0.5rem 1rem;
  color: var(--textColor-primary);
  border: none;
  border-radius: 6px;
  margin: 5px 5px 5px 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.32px;
}

.badge-wrapper-news .active-btn {
  background-color: #f5f5f5;
}

.news-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(50px, 1fr));
  grid-template-rows: auto;
  width: fit-content;
  gap: 32px;
}

@media all and (max-width: 1000px) {
  .news-container {
    padding: 1.5rem 2rem;
  }

  .badge-wrapper-news {
    margin-bottom: 4rem;
    display: flex;
    justify-content: space-around;
    gap: unset;
  }

  .news-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 32px;
  }
}
