.menu-trigger {
  position: relative;
  cursor: pointer;
  padding:0;
  width: 100%;
}

.dropMenu {
  position: absolute;
  top: 1.8rem;
  left: -4rem;
  background-color: #fff;
  border-radius: 6px;
  padding: 1rem;
  z-index: 1;
  width: 160px;
  box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1),
    0px 10px 15px -3px rgba(16, 24, 40, 0.1);
}

.dropMenu > ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.dropMenu > ul > li {
  border-radius: 6px;
  margin-bottom: 2rem;
  max-width: 100%;
  font-size: 1rem;
  padding: 0 1.75rem;
}

.drop-item:hover {
  background-color: #f5f5f5;
}

@media all and (max-width: 1000px) {
  .dropMenu {
    position: relative;
    top: 0.5rem;
    left: -8.5rem;
    background-color: #fff;
    border-radius: 6px;
    padding: 1 rem 0.5rem;
    z-index: auto;
    width: 200px;
    box-shadow: none;
    height: 300px;
  }

  .dropMenu > ul > li {
    border-radius: 6px;
    margin-bottom: 2.5rem;
    max-width: 100%;
    font-size: 1.5rem;
    padding: 0 1.75rem;
  }
}
