.form-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
}

.form-header > h2 {
  font-size: 2.5rem;
}

.contact-container .navbar-container {
  top: 0%;
}

.form-header > p {
  font-size: 1.5rem;
  font-weight: 300;
}

.contact-form-container {
  display: flex;
  margin: 5rem auto;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  position: relative;
  width: 60%;
  margin-bottom: 6rem;
}

.contact-form-container > img,
.contact-form-container > form {
  width: 50%;
}

.contact-form-container .pink-cover {
  background-color: rgba(255, 182, 193, 0.2);
  width: 80%;
  position: absolute;
  height: 120%;
  z-index: -10;
  left: 30%;
  border-radius: 10px;
}

.contact-form-container form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.contact-form-container .input-data {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact-form-container .input-data > input,
.contact-form-container .textarea > textarea {
  border: 1.5px solid #082567;
  box-shadow: 4px 4px 4px 0px rgba(8, 37, 103, 0.5);
}

.contact-form-container form > button {
  border: none;
  background-color: #082567;
  color: #fff;
  padding: 1rem;
  border-radius: 10px;
}

@media all and (max-width: 1000px) {
  .form-header {
    margin-top: 3rem;
  }

  .contact-form-container {
    width: 60%;
    margin-bottom: 6rem;
  }

  .contact-form-container .pink-cover {
    display: none;
  }
}

@media all and (max-width: 800px) {
  .contact-form-container {
    width: 100%;
    margin-bottom: 3rem;
  }
}

@media all and (max-width: 550px) {
  .contact-form-container > form {
    width: 100%;
    padding-inline: 1.5rem;
  }
}
