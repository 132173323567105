@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");

* {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

:root {
  --primary-blue: #082567;
  --textColor-primary: #082567;
  --secondary-pink: #ffb6c1;
}

a {
  text-decoration: none;
  color: var(--primary-blue);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Montserrat, serif;
}

button {
  cursor: pointer;
}

nav {
  padding: 0 1.5rem;
  border: 2px solid #f5f5f5;
  color: var(--textColor-primary);
}

nav a {
  color: var(--textColor-primary);
}

nav>div {
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

nav div article {
  display: flex;
  flex-flow: row wrap;
  gap: 32px;
}

nav article p.dropdownItem,
nav article p {
  margin-block: auto;
}

.programArticle img {
  vertical-align: middle;
}

nav .programArticle p {
  padding: 0;
  margin-inline: 5px;
}

.active {
  background-color: var(--secondary-pink);
  padding: 4px 8px;
  border-radius: 10px;
  color: var(--primary-blue);
  max-height: 100%;
}

.programArticle p {
  margin-inline-end: 1rem;
  display: inline-block;
}

.burgerIcon {
  width: 56px;
  vertical-align: middle;
  margin-bottom: -1.5rem;
}

.hide {
  display: none;
}
.hide.mobileImg {
  border-radius: 20px;
  /* i dont want to resize the full image jsu like half of 80% of it */
  width: 80%;
  height: 80%;
  object-fit: contain;
  
  
 }

.primBtn {
  background-color: var(--primary-blue);
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  border-radius: 5px;
}

.pinkBtn {
  background-color: var(--secondary-pink);
  padding: 0.5rem 1rem;
  color: var(--textColor-primary);
  border: none;
  border-radius: 5px;
}

.dropdownItem {
  padding: 5px;
  cursor: default;
  display: inline-block;
  max-width: 100%;
  border-radius: 3px;
}

.dropdownItem:hover {
  background-color: #f5f5f5;
}

.dropdownMenu {
  display: none;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1),
    0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  border-radius: 0 0 6px 6px;
  position: absolute;
  background-color: white;
  transform: scaleY(0);
  transform-origin: top;
  transition: all 0.1s ease-in;
  z-index: 1;
}

.dropdownMenu.program {
  margin-top: 2rem;
  text-align: center;
  margin-inline-start: 6.5rem;
}

.dropdownMenu.facilities {
  margin-top: 2rem;
  margin-inline-start: 14.5rem;
  text-align: center;
  width: 12rem;
}

.dropdownMenu.facilities .dropdownItem {
  width: 10rem;
  margin-inline: auto;
}

.dropdownMenu.about {
  margin-top: 2rem;
  margin-inline-start: 22rem;
}

nav li>img {
  vertical-align: middle;
}

.program,
.facilities,
.about {
  cursor: default;
}

.show {
  transform: scaleY(1);
  display: flex;
}

.intro {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 4rem 7rem;
  text-align: center;
  position: relative;
}

.intro img {
  max-width: 100%;
}

.circles {
  position: absolute;
  top: 10rem;
  left: 0;
  max-width: 100%;
  z-index: -1;
}

.intro h1 {
  font-size: 52px;
  width: min(48rem, 90vw);
  line-height: 56px;
  color: var(--textColor-primary);
}

.heroArticle {
  background: url(./assets/cirlces.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

}

.heroArticle img {
  max-width: 80%;
  max-height: 80%;
  border-radius: 15px;
}

.intro p {
  font-size: 20px;
  line-height: 28px;
}

.intro>article:not(.heroArticle) {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.intro>article button {
  max-width: 14rem;
  margin: auto;
}

.intro img {
  margin-top: 3rem;
}

.programs>h2,
.programs>p,
.facilitiesSection>p,
.facilitiesSection>h2 {
  text-align: center;
}

.programs>p,
.facilitiesSection>p {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 4rem;
}

.programs>h2,
.facilitiesSection>h2 {
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 0.5rem;
  color: var(--textColor-primary);
}

.badge {
  background-color: white;
  padding: 0.2rem 0.5rem;
  color: var(--textColor-primary);
  border: none;
  border-radius: 5px;
  margin: 5px 5px 5px 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.32px;
}

.card {
  width: 20rem;
  background-color: #f5f5f5;
  height: 34rem;
  position: relative;
}

.card p {
  font-size: 16px;
  line-height: 24px;
  margin-block-end: 0.5rem;
}

.card h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.48px;
  margin-block: 0.5rem;
}

.card article {
  max-width: 100%;
  padding-inline: 10px;
  padding-bottom: 5px;
  white-space: pre-wrap;
}

.card img {
  width: 100%;
  margin-bottom: 0.5rem;
}

.programs {
  padding-inline: 1rem;
  margin-bottom: 8rem;
}

.cards {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto;
  width: fit-content;
  gap: 1rem;
  margin: auto;
}

.card a {
  position: absolute;
  bottom: 10px;
}

.facilitiesSection {
  margin-top: 10rem;
  margin-bottom: 8rem;
}

.facilitiesSection>p,
.facilitiesSection>h2 {
  width: 30rem;
  word-wrap: break-word;
  margin-inline: auto;
}

.basicCard {
  background-color: #f5f5f5;
  height: 27rem;
  position: relative;
  border-radius: 5px;
}

.basicCard .content {
  padding-inline: 5px;
}

.basicCards {
  display: grid;
  grid-template-columns: 30% 35% 30%;
  grid-template-rows: 27rem;
  gap: 1rem;
  padding-inline: 2rem;
  justify-content: center;
  margin-bottom: 5rem;
}

.basicCard>img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.basicCard .firstRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.basicCard .secondRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.basicCard.active {
  height: 27rem;
}

.carouselControl {
  display: flex;
  flex-direction: row;
  padding: 2px;
  justify-content: space-between;
  align-items: center;
  width: 8rem;
  margin-inline: auto;
}

.inquirySection,
.signupSection {
  background-color: var(--primary-blue);
  background-image: url(./assets/Elements.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 7.5rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.inquirySection h3,
.signupSection h3 {
  color: white;
  font-size: 36px;
  line-height: 44px;
}

.inquirySection p,
.signupSection p {
  color: white;
  font-size: 20px;
  line-height: 28px;
  margin-block: 1rem;
}

.inquirySection .orangeBtn {
  transition: 0.2s all ease-in-out;
}

.inquirySection .orangeBtn:hover {
  background-color: #ff6738;
}

.memoryMoments {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto;
  gap: 1rem;
  justify-content: space-evenly;
  align-items: center;
  padding-inline: 1rem;
  margin-bottom: 5rem;
  max-width: 100vw;
}

.contentOne {
  /*background-color: #f5f5f5;*/
  height: 100%;
  padding-left: 5px;
}

.contentOne h3 {
  margin-top: 6rem;
  margin-bottom: 1rem;
}

.contentOne p {
  margin-bottom: 1rem;
}

.signupSection label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.signupSection label input {
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  width: 20rem;
}

.signupSection label button {
  margin-left: 5px;
}

.testimonialsWrapper {
  padding: 4rem 7rem;
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonialsWrapper .heading {
  text-align: center;
  padding-block: 1rem;
  color: var(--primary-blue);
}

.testimonialsWrapper .heading h2 {
  color: var(--textColor-primary);
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
}

.testimonialsWrapper .heading p {
  width: 70%;
  margin-inline: auto;
  margin-bottom: 0.5rem;
}

.testimonialsWrapper .content {
  max-width: 40rem;
  white-space: pre-wrap;
  padding: 1.5rem 4rem;
  word-wrap: break-word;
  margin-right: 1rem;
  height: 20rem;
  background-color: #fafafa;
  text-align: center;
  display: inline-block;
}

.testimonialsWrapper .content cite {
  display: block;
  margin-bottom: 1.5rem;
}

.testimonialsWrapper .content span {
  display: inline-block;
  margin-top: 5px;
}

.missionSection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 1.5rem;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1440px;
  padding: 4rem 7rem ;
  padding-bottom: 2rem;
   margin-inline: auto;
  word-break: break-word;
}

.avatars {
  margin-top: 1.5rem;
}

.avatars img {
  margin-right: 20px;
}

.missionSection article img {
  max-width: 100%;
}

.missionSection .contentOne {
  grid-row: span 2;
  grid-column: span 2;

}

.missionSection .contentOne h2 {
  color: var(--textColor-primary);
  font-size: 36px;
  line-height: 44px;
  margin-top: 14rem;
}

.missionArticle.hide {
  width: min(35rem, 95vw);
  margin-inline: auto;
}

.missionArticle.hide img {
  width: 100%;
}

.mapImg {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  /*20.75rem*/
  background: url(./assets/map.jpg), lightgray 50% / cover no-repeat;
  display: flex;
  align-items: center;
}

.emptyDiv {
  width: 100%;
  /* height: 25rem; */

}

.contactDiv .formDiv {
  background-color: #f5f5f5;
  width: 40rem;
  padding: 1rem;
}

.contactDiv .formDiv p:first-child {
  color: var(--textColor-primary);
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  /* 122.222% */
  letter-spacing: -0.72px;
  margin-bottom: 1rem;
}

.contactDiv p {
  margin-bottom: 3rem;
}

.contactDiv .formFirstRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.contactDiv .formFirstRow input {
  width: 15rem;
  padding: 8px 10px;
  border-radius: 5px;
  border: none;
}

.contactDiv textarea {
  width: 100%;
  border-radius: 5px;
  border: none;
  resize: none;
  margin-bottom: 1rem;
  padding: 0.5rem;
  color: var(--textColor-primary);
}

.contactSection {
  position: relative;
}

.contactDiv {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contactDiv .details {
  background-color: #f5f5f5;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem 0.5rem;
  width: 15rem;
  height: 6rem;
}

.contactDiv .details:last-child {
  height: 8rem;
}

.contactDiv .details.mail a {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  column-gap: 5px;
}

.contactDiv .details.location {
  padding: 0;
}

.contactDiv .details.location a {
  background-color: #f5f5f5;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem 0.5rem;
  width: 15rem;
  height: 6rem;
}

.details img {
  max-height: 28px;
}

.contactDetails> :not(:last-child) {
  margin-bottom: 1rem;
}

.contactDetails {
  margin-right: 1rem;
  margin-top: 14rem;
}

.details.phone article {
  margin-left: -2rem;
}

.footer {
  background-color: var(--primary-blue);
  background-image: url(./assets/Elements.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 2rem 1.5rem;
  position: relative;
}

.footer .majorContent {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  justify-content: space-between;
  padding-inline: 10rem;
  column-gap: 1rem;
  margin-bottom: 2rem;
}

.footer .majorContent .links {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
}

.footer .majorContent .links>article {
  max-width: 40%;
  margin-right: 1rem;
}

.logoDiv {
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.majorContent p {
  font-weight: bolder;
}

.majorContent,
.majorContent a {
  color: white;
}

.majorContent article:not(.logos, .links) {
  display: flex;
  flex-direction: column;
}

.majorContent article a {
  margin-block: 5px;
}

.logos a {
  margin-right: 10px;
}

.minorContent {
  color: white;
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.swiper {
  width: min(40rem, 90vw);
  height: 34rem;
  background-color: red;
}

/* Carousel */
.wrapper {
  display: flex;
  /* max-width: 1200px; */
  position: relative;

  margin-bottom: 4rem;
}

.testimonialsWrapper .wrapper {
  max-width: 41rem;
}

.programsWrapper,
.newsWrapper,
.facilitiesWrapper {
  padding: 4rem 2rem;
  width: 100%;
  max-width: 1440px;
  margin: auto;
}

.programsWrapper .heading,
.newsWrapper .heading {
  max-width: 1200px;
  padding-inline: 1rem;
  margin-bottom: 1rem;
}

.programsWrapper .heading,
.newsWrapper .heading {
  max-width: 1048px;
}

.programsWrapper .heading h2,
.newsWrapper .heading h2 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.72px;
  margin-bottom: 1rem;
}

.programsWrapper .heading p,
.newsWrapper .heading p {
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* width: 20rem; */
  letter-spacing: -0.4px;
  margin-bottom: 3rem;
}

.wrapper i {
  top: -10%;
  height: 48px;
  width: 48px;
  color: var(--primary-blue);
  cursor: pointer;
  font-size: 1.15rem;
  position: absolute;
  text-align: center;
  line-height: 48px;
  background: #fff;
  border: 1px solid var(--primary-blue);
  border-radius: 50%;
  transform: translateY(-50%);
  transition: all 0.1s linear;
  box-shadow: 0.1px 0.1px 20px 0.1px var(--primary-blue);
}

.wrapper i:active {
  transform: translateY(-50%) scale(0.9);
}

.wrapper i:hover {
  background: var(--primary-blue);
  color: #fff;
}

.wrapper i:first-child {
  right: 85px;
  display: none;
}

.wrapper i:last-child {
  right: 30px;
}

.testimonialsWrapper .wrapper i {
  top: 50%;
}

.testimonialsWrapper .wrapper i:first-child {
  left: -100px;
}

.testimonialsWrapper .wrapper i:last-child {
  right: -80px;
}

.programOverview .wrapper i {
  top: 40%;
}

.programOverview .wrapper i:first-child {
  left: -100px;
}

.programOverview .wrapper i:last-child {
  right: -90px;
}

.wrapper .carousel {
  overflow: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  touch-action: pan-y;
}

.newsWrapper .wrapper .carousel,
.programsWrapper .wrapper .carousel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-content: flex-start;
}

.testimonialsWrapper .wrapper .carousel {
  display: block;
}

.carousel.dragging {
  cursor: grab;
  scroll-behavior: auto;
}

.carousel.dragging img {
  pointer-events: none;
}

.carousel .card,
.carousel .basicCard {
  display: inline-block;
  height: 34rem;
  object-fit: cover;
  user-select: none;
  margin-left: 14px;
  vertical-align: middle;
  border-radius: 10px;
}

.newsWrapper .carousel .card,
.programsWrapper .carousel .card {
  min-width: 20.6rem;
}

.testimonialsWrapper .carousel .card {}

.carousel .card img,
.carousel .basicCard img {
  border-radius: 10px 10px 0px 0px;
}

/* .carousel .card:first-child {
  vertical-align: top;
} */
.carousel .card .content,
.carousel .basicCard .content {
  max-height: 100%;
}

.basic p:first-child {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: Open Sans;
  margin-block: 10px;
}

@media screen and (max-width: 900px) {

  .carousel .card,
  .carousel .basicCard {
    width: calc(100% / 2);
    height: 37rem;
  }
}

@media screen and (max-width: 650px) {
  .carousel .basicCard {
    width: 100%;
    height: 35rem;
  }

  .carousel .card {
    /* width: 100%; */
    width: 18rem;
    height: 28rem;
  }

  .carousel .card img {
    height: 14rem;
  }

  .inquirySection .content {
    padding-inline: 1rem;
  }

  /* .wrapper .carousel {
  overflow-y: unset;
  white-space: unset;
  scroll-behavior: unset;
  touch-action: unset;

    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    max-width: 100%;
} */

}

.carousell {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  max-width: 100vw;

}

.carousell::-webkit-scrollbar {
  height: 0 !important;
}

.wrapper .carousell {
  /* overflow: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  touch-action: pan-y; */
}

.newsWrapper .wrapper .carousell,
.programsWrapper .wrapper .carousell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-content: flex-start;
}

.testimonialsWrapper .wrapper .carousell {
  display: block;
}

.carousell.dragging {
  cursor: grab;
  scroll-behavior: auto;
}

.carousell.dragging img {
  pointer-events: none;
}

.carousell .card,
.carousell .basicCard {
  display: inline-block;
  height: 34rem;
  object-fit: cover;
  user-select: none;
  margin-left: 14px;
  vertical-align: middle;
  border-radius: 10px;
}

.newsWrapper .carousell .card,
.programsWrapper .carousell .card {
  min-width: 20.6rem;
}

.testimonialsWrapper .carousell .card {}

.carousell .card img,
.carousell .basicCard img {
  border-radius: 10px 10px 0px 0px;
}

.carousell .card .content,
.carousell .basicCard .content {
  max-height: 100%;
}

.carousell .card,
.carousell .basicCard {
  width: calc(100% / 2);
  height: 37rem;
}

.carousell .basicCard {
  width: 100%;
  height: 35rem;
}

.carousell .card {
  width: 100%;
  width: 18rem;
  height: 28rem;
}

.carousell .card img {
  height: 14rem;
}

.inquirySection .content {
  padding-inline: 1rem;
}

.sss {
  display: none !important;
}

.sui {
  display: flex !important;

}

.sssvv {
  display: none !important;
}

.suivv {
  display: unset !important;

}

@media screen and (max-width: 1000px) {

  .sss {
    display: flex !important;
  }

  .sui {
    display: none !important;

  }

  .sssvv {
    display: flex !important;
  }

  .suivv {
    display: none !important;

  }

  .sssvv .content {
    min-width: 95vw;
  }


}