.carousel-container {
  padding: 4rem 7rem;
  max-width: 1440px;
  margin: auto;
}

.slick-prev,
.slick-next {
  color: #082567;
  border: 1px solid #082567;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  top: -8%;
}

.slick-next {
  right: 0;
}

.slick-prev {
  left: unset;
  right: 50px;
}

.slick-prev:before,
.slick-next:before {
  color: #082567;
}

@media all and (max-width: 1000px) {
  .carousel-container {
    padding: 4rem 2rem 1.5rem 2rem;
  }
}
