.singleNew-container {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: start;
  min-height: 60vh;
}

.singleNew-container > h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.singleNew-container > h3 {
  font-size: 1rem;
  font-weight: 400;
  text-align: justify;
  margin-bottom: 0.5rem;
}

.singleNew-container .new-date {
  font-style: italic;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.new-content > img {
  float: left;
  width: 40%;
  margin-right: 2rem;
  border-radius: 8px;
}

.new-content > p {
  text-align: justify;
}

@media all and (max-width: 900px) {
  .singleNew-container {
    max-width: 90%;
  }
  .new-content > img {
    float: unset;
    width: 100%;
    margin-bottom: 2rem;
  }
}
