.admissionForm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 7rem;
}

.admission-form {
  background-color: #fafafa;
  padding: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 6px;
}

.admission-form h3 {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: -0.36px;
}

.admission-form label {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.32px;
}

.admission-form section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.form-row .input-data {
  width: 45%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-data input,
.textarea textarea {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background: #fff;
  margin-top: 0.3rem;
}

.textarea textarea {
  resize: none;
  height: 108px;
}

@media all and (max-width: 1000px) {
  .admissionForm-container {
    padding: 1.5rem 2rem;
  }
  .form-row {
    flex-direction: column;
    gap: 2rem;
  }

  .form-row .input-data {
    width: 100%;
  }
}
