.introAbout {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  position: relative;
  padding: 4rem 7rem;
}

.about-mission,
.about-vision {
  display: flex;
  justify-content: space-between;
  padding: 4rem 7rem;
  gap: 3rem;
  max-width: 1440px;
  margin-inline: auto;
}

.circles-about {
  position: absolute;
  top: 10rem;
  left: 0;
  max-width: 100%;
  z-index: -1;
}

.mobile-img {
  display: none;
}

.about-mission .mission-gallery,
.about-vision .vision-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.about-mission .mission-gallery > div > img,
.about-vision .vision-gallery > div > img {
  width: 100%;
  height: 100%;
}

.about-mission .mission-gallery .mission-img-three,
.about-vision .vision-gallery .vision-img-three {
  grid-column: 1 / span 2;
}

.about-mission .mission-content,
.about-vision .vision-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.about-mission .mission-content > p,
.about-vision .vision-content > p {
  width: 90%;
  text-align: justify;
}
.about-mission .mission-content > a,
.about-vision .vision-content > a {
  width: fit-content;
  margin-top: 1rem;
}

@media all and (max-width: 1000px) {
  .introAbout {
    padding: 1.5rem 2rem;
  }
  .introAbout > div,
  .mission-content > div,
  .about-vision > div {
    width: 100%;
  }
  .mobile-img {
    display: block;
    width: 100%;
  }
  .about-mission,
  .about-vision {
    padding: 1.5rem 2rem;
  }
  .about-mission .mission-content > p,
  .about-vision .vision-content > p {
    width: 100%;
  }
}
