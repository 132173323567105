.navbar-trial {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  height: 100%;
}

.navbar-container {
  height: 80px;
  background-color: #fff;
  border-bottom: 2px solid #f5f5f5;
  position: fixed;
  z-index: 1;
  width: 100%;
}

.burger-icon-nav {
  width: 56px;
  vertical-align: middle;
  margin-bottom: -1.5rem;
  display: none;
}

.burger-icon-nav img {
  width: 100%;
}

.navtrial-links ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.navtrial-links ul li:not(:last-child) {
  padding: 0 1.5rem;
}

.btnHide {
  display: none;
}

.dropdown-container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: -1rem;
}

@media all and (max-width: 1000px) {
  .burger-icon-nav {
    display: block;
    cursor: pointer;
  }

  .navtrial-links {
    position: absolute;
    right: 0;
    top: 70px;
    background-color: white;
    width: 0px;
    height: 100vh;
    transition: all 0.3s ease-in;
    overflow: hidden;
    border-radius: 5px;
    z-index: 1;
  }

  .btnHide {
    display: block;
    padding: 0.5rem 0;
  }

  .btnHide>button {
    font-size: 1.5rem;
    padding: 0.75rem 1.75rem;
  }

  .btntoHide {
    display: none;
    padding: 0rem 0;
  }

  .navtrial-links.active-navlinks {
    width: 100vw;
  }

  .navtrial-links>ul {
    display: flex;
    flex-direction: column;
    padding: 4rem 2rem;
    align-items: center;
  }

  .navtrial-links>ul>li {
    margin-right: 0;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

    .navtrial-links-buttons {
      display: none;
    }

  .navbar-trial {

    padding: 0 0 0 1.5rem;
  }
}

@media (min-width: 768px) {
  .navtrial-links-buttons {
    display: flex;
    gap: 1rem;
  }
}