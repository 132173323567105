.card-component {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  white-space: pre-wrap;
  border-radius: 10px;
}

.card-component .card-img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-component .card-body {
  padding: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.card-component p {
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-component h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
}
