.slider-card-container {
  border-radius: 10px;
  overflow: hidden;
  margin-inline: 1rem;
  background-color: #fafafa;
}
.slider-card-container > img {
  width: 100%;
}

.slider-card-container > div {
  padding: 1rem;
}

.slider-card-container > div > h3 {
  padding-bottom: 1rem;
}

.slider-card-container > div > section {
  padding: 1rem 0;
}

.slider-card-container > div > a {
  color: #0071c5;
}
