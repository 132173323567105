@media all and (max-width: 1000px) {
  .navHide {
    display: none;
  }
  .hide {
    display: block;
  }
  .toBeHidden {
    display: none !important;
  }
  nav > div {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto auto auto auto auto auto;
    justify-content: space-between;
  }
  nav > div article {
    display: grid;
    grid-row: 2 / span 6;
    grid-column: 1 / span 2;
    text-align: center;
    width: 100%;
    margin-inline: auto;
    row-gap: 1rem;
  }
  nav div{
    position: relative;
  }
  nav > div article > * {
    border-bottom: 2px solid #f5f5f5;
    padding-bottom: 5px;
  }
  nav p.hide img {
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 20px;
  }
  .menuLink.btn {
    grid-column: 1 / span 2;
    text-align: center;
    margin-top: 1rem;
  }
  .facilities {
    margin-inline: auto;
    width: 100%;
  }
  .intro {
    background-color: white;
    background-image: none;
    flex-direction: column;
    padding: 2rem 1.5rem;
  }
  .intro > img:not(.hide) {
    display: none;
  }
  .intro > img.hide {
    width: 100%;
  }

  .inquirySection button {
    font-size: large;
    width: 12rem;
    margin-top: 1rem;
  }
  .missionSection {
    padding: 0 1rem;
    grid-template-columns: auto;
    grid-row: auto auto;
    margin-bottom: 2rem;
  }
  .montessoriMemoryMoments {
    grid-template-columns: auto;
    grid-row: auto auto;
  }
  .missionSection .contentOne {
    /* grid-column: span 4; */
    /* padding: 1rem; */
    max-width: 100%;
    background-color: white;
    padding-inline: 0;
    margin: auto;
  }
  .missionSection .contentOne h2 {
    margin-top: 1rem;
  }
  .missionSection .contentOne p {
    margin-block: 1rem;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 2rem;
  }
  .missionSection .contentOne button {
    font-size: large;
    padding: 12px 20px;
  }
  .container {
    padding: 1rem;
  }
  .container .testimonial h2 {
    margin-bottom: 1rem;
  }
  .container .testimonial p {
    font-size: 25px;
    width: 100%;
    color: #003963;
  }
  .contactDiv .formDiv {
    max-width: 95vw;
    margin-top: 5rem;
  }
  .footer {
    margin-top: 7rem;
    height: fit-content;
    padding: 2rem 1.5rem;
  }
  .footer .majorContent {
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto;
  }
  .dropdownMenu {
    position: relative;
  }
  .dropdownMenu.program {
    width: 35%;
    margin-inline-start: 0;
    margin-inline: auto;
    margin-top: 1px;
  }
  .dropdownMenu.facilities {
    width: 35%;
    margin-inline-start: 0;
    margin-inline: auto;
    margin-top: 1px;
  }
  .dropdownMenu.about {
    width: 35%;
    margin-inline-start: 0;
    margin-inline: auto;
    margin-top: 1px;
  }
  .facilitiesWrapper .heading * {
    margin-block: 1rem;
  }
  .facilitiesWrapper .heading p {
    margin-block: 1rem;
    margin-bottom: 3rem;
  }
  nav article li {
    margin-inline: 0;
  }
  .montessoriMemoryMoments .rowOne .contentThree,
  .montessoriMemoryMoments .rowTwo .contentSix {
    display: none;
  }
  article.montessoriMemoryMoments {
    padding-inline: 0;
  }
  article.programOverview {
    margin-bottom: 0;
  }
  article.rowTwo {
    margin-bottom: 0;
  }
  .montessoriMemoryMoments article.rowTwo {
    display: none;
  }
  .intro > article:not(.heroArticle) {
    margin-bottom: 0;
    margin-top: 4rem;

  }
  article.programs {
    margin-bottom: 0;
  }
}
@media all and (max-width: 600px) {
  .signupSection label input {
    width: 100%;
    padding: 12px 24px;
  }
  .signupSection label {
    margin-inline: auto;
    flex-direction: column;
    row-gap: 5px;
    padding-inline: 1rem;
  }
  .card {
    max-width: 80%;
  }
  .facilitiesWrapper i {
    display: none;
  }
  .testimonialsWrapper {
    padding: 2rem 1.5rem;
  }
  .testimonialsWrapper .content {
    max-width: 100%;
    padding: 1.5rem 0;
  }
  .contactDiv {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
  .contactDiv .formFirstRow {
    flex-direction: column;
  }
  .contactDiv .formFirstRow input {
    width: 20rem;
  }
  .contactDiv textarea {
    height: 10rem;
    overflow-y: auto;
  }
  .contactDiv article.formDiv {
    margin-top: 0;
  }
  .contactDiv .formFirstRow {
    align-items: normal;
  }
  .contactDiv .formFirstRow label {
    margin-bottom: 1rem;
  }
  .contactDiv p {
    margin-bottom: 1.5rem;
  }
  .mapImg {
    height: 20.75rem;
    aspect-ratio: auto;
    white-space: nowrap;
    z-index: -1;
    position: absolute;
    top: -2rem;
  }
  .card {
    height: 25rem;
  }
  .card p {
    font-size: 12px;
    line-height: 18px;
    margin-block-end: 0.2rem;
  }
  .card h3 {
    font-size: 18px;
    line-height: 26px;
    margin-block: 0.2rem;
  }
  .intro h2 {
    font-size: 45px;
  }
  .missionArticle.hide {
    padding-inline: 1rem;
    margin-bottom: 4rem;
  }
  .signupSection label button {
    padding: 12px 20px;
    margin-left: 0;
  }
  .quickLinks,
  .about,
  .contact {
    margin-inline: 0;
  }
  .programArticle p {
    margin-inline-end: 5px;
  }
  .intro > article:not(.heroArticle) {
    text-align: center;
    padding-inline: 15px;
  }
  .intro > article:not(.heroArticle) button {
    max-width: 18rem;
  }
  .intro > img {
    margin-block: 2rem;
  }
  .programsWrapper,
  .newsWrapper,
  .facilitiesWrapper {
    padding: 0;
  }
  .footer {
    margin-top: 0;
  }
  .footer .majorContent {
    padding-inline: 0;
    margin-bottom: 0;
  }
  .footer .majorContent .links {
    justify-content: start;
    flex-direction: column;
    margin-block: 3.75rem;
  }
  .footer .majorContent .links > article {
    margin-right: 0rem;
    margin-bottom: 1rem;
  }
  .footer .logos {
    margin-top: 0;
    margin-bottom: 2rem;
  }
  .testimonialsWrapper .heading {
    padding-block: 0px;
  }
  .testimonialsWrapper .heading h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.64px;
    margin-bottom: 1rem;
  }
  .testimonialsWrapper div.heading p {
    width: 100%;
    margin-bottom: 2rem;
  }
  .programsWrapper .heading h2 {
    font-size: 28px;
  }
  .programsWrapper .heading p {
    font-size: 18px;
  }
  .programsWrapper .heading {
    padding-inline: 1.5rem;
  }
  article.programsPage.intro h2 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    /* 122.222% */
    letter-spacing: -0.72px;
    margin: 0;
    width: auto;
    margin-bottom: 1rem;
  }
  article.programsPage.intro p{
    margin-bottom: 1.5rem;
  }
  article.curriculum {
    padding: 2rem 1.5rem;
  }
  article.curriculum > h2 {
    font-size: 32px;
    line-height: 44px;
  }
  article.curriculum>p:not(.descText) {
    font-size: 18px;
    line-height: 28px;
  }
  article.programOverview {
    padding: 2rem;
  }
  .programOverview article.horizontalCard {
    flex-direction: column;
    padding: 24px;
    max-width: 95vw;
  }
  .programOverview .wrapper {
    max-width: 100%;
  }
  article.montessoriMemoryMoments {
    padding-block: 2rem;
  }
  article.rowOne {
    margin-bottom: 0;
  }
  .rowOne article {
    margin-bottom: 1rem;
  }
  article.facilities.intro {
    padding: 2rem 1.5rem;
  }
  article.classrooms,
  article.playAreas,
  article.computerLab {
    padding: 2rem 1.5rem;
    padding-bottom: 0;
  }
  article.classrooms .contentOne,
  article.playAreas .contentOne,
  article.computerLab .contentOne {
    max-width: 100%;
  }
  article.missionArticle.hide {
    width: 100%;
    padding: 1.5rem;
    margin-bottom: 1rem;
  }
  article.contentOne {
    padding: 0;
  }
  article.classrooms .contentOne p,
  article.playAreas .contentOne p,
  article.computerLab .contentOne p {
    font-size: 18px;
    line-height: 28px;
  }
  article.rowOne article {
    height: fit-content;
  }
  article.rowOne article.contentOne {
    padding: 1.5rem;
  }
  article.contentOne h3 {
    margin-top: 0;
  }
}
