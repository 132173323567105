:root {
  --primary-color: #082567;
}
body {
  color: var(--primary-color);
}
.curriculum {
  padding: 4rem 7rem;
  margin: auto;
  max-width: 1440px;
}
.simpleCard {
  background-color: #fafafa;
  position: relative;
  padding: 1rem;
  border-radius: 5px;
}
.simpleCard p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-block: 1.5rem;
}

.simpleCard article {
  display: inline-block;
  padding-inline: 5px;
  padding-bottom: 5px;
}

.simpleCard img {
  width: 4rem;
  margin-bottom: 1rem;
}
.curriculum > h2,
.curriculum > p {
  text-align: center;
}

.curriculum > p:not(.descText) {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 4rem;
  max-width: 780px;
  margin-inline: auto;
}
.descText {
  margin-bottom: 10px;
  font-size: 15px;
}
.curriculum > h2 {
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 0.5rem;
  color: var(--textColor-primary);
}
.simpleCard a {
  position: absolute;
  bottom: 10px;
}
.simpleCards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 1rem;
}
.programOverview {
  padding: 4rem 7rem;
}
.programOverview > h2 {
  text-align: center;  
  font-size: 36px;
  line-height: 44px;
}
.programOverview>p {
  margin-block: 1rem;
  text-align: center;
}
.programOverview .wrapper {
  width: 51.5rem;
  margin-inline: auto;
}
.programOverview .horizontalCard {
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 3rem;
  white-space: pre-wrap;
  width: 100%; 
}
.horizontalCard article {
  background-color: #fafafa;
  padding: 1.5rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.horizontalCard article h3 {
  font-size: 22px;
}
.horizontalCard article .primBtn {
  width: 8rem;
}
.montessoriMemoryMoments {
  grid-template-columns: auto;
  grid-template-rows: auto;
  row-gap: 1rem;
  padding: 4rem 7rem;
}
.montessoriMemoryMoments .rowOne {
  display: grid;
  grid-template-columns: 1fr 1.46fr 1.46fr;
  grid-template-rows: 20rem;
  justify-content: center;
  column-gap: 1rem;
  padding-inline: 2rem;
  align-items: center;
}
.rowOne img,
.rowTwo img {
  width: 100%;
  min-height: 100%;
}
.rowOne article {
  height: 18rem;
  overflow-y: hidden;
  background-color: #f5f5f5;
  border-radius: 6px;
}
.rowOne {
  margin-bottom: 0.7rem;
}
.rowTwo {
  margin-bottom: 3rem;
}
.rowTwo article {
  height: 20rem;
}
.montessoriMemoryMoments .rowOne .contentThree img {
  width: 100%;
}
.montessoriMemoryMoments .rowTwo {
  display: grid;
  grid-template-rows: 20rem;
  grid-template-columns: 1fr 1.97fr 1fr;
  column-gap: 1rem;
  justify-content: center;
  align-items: center;
  padding-inline: 2rem;
}
.montessoriMemoryMoments .rowTwo .contentFive img {
  width: 100%;
  height: 20rem;
}
.contentOne h3 {
  margin-top: 4rem;
}
.contentOne {
  padding: 1rem;
}
.programsPage.intro h2{
  font-size: 52px;
  width: min(47rem, 80%);
  margin: auto;
  line-height: 1;
}
.programsPage.intro button {
  margin-top: -10px;
}
.programsPage.intro img {
  margin-top: 2rem;
}
@media all and (max-width: 900px) {
  .simpleCards {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
  }
  .horizontalCard {
    flex-direction: column;
    width: min(35rem, 80vw);
    margin-right: 25px;
  }
  .montessoriMemoryMoments .rowOne {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    row-gap: 1rem;
    justify-content: center;
  }
  .montessoriMemoryMoments .rowTwo {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    row-gap: 1rem;
    justify-content: center;
  }
  .montessoriMemoryMoments .rowTwo img {
    height: 100%;
    width: 100%;
  }
  .montessoriMemoryMoments .rowOne .contentThree,
  .montessoriMemoryMoments .rowTwo .contentSix {
    grid-column: 1 / span 2;
    display: none;
    justify-content: center;
  }
  .montessoriMemoryMoments .rowOne .contentThree img,
  .montessoriMemoryMoments .rowTwo .contentSix img {
    max-width: 60%;
    text-align: center;
  }
}

@media all and (max-width: 700px) {
  .montessoriMemoryMoments .rowOne {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    row-gap: 1rem;
    justify-content: center;
  }

  .montessoriMemoryMoments .rowTwo {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    row-gap: 1rem;
    justify-content: center;
  }
}
