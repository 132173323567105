.admissionsteps-container {
  padding: 4rem 7rem;
  margin: auto;
}

.headText {
  width: min(45rem, 90%);
  margin-inline: auto;
  text-align: center;
  margin-bottom: 6.25rem;
}

.headText h2 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 1rem;
  letter-spacing: -0.72px;
}

.admission-steps {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  row-gap: 3.5rem;
  column-gap: 2rem;
}

.admission-steps .text-container {
  width: 100%;
}

.admission-steps .image-container img {
  width: 100%;
}

.curriculum-admission {
  padding: 4rem 7rem;
  margin: auto;
  max-width: 1440px;
}

.curriculum-admission h2 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.75rem; /* 137.5% */
  letter-spacing: -0.64px;
  margin-bottom: 1rem;
  text-align: center;
}

.curriculum-admission div p {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 155.556% */
  letter-spacing: -0.36px;
  margin-bottom: 2.25rem;
  text-align: center;
}

.missionSection .contentOne h2 {
  margin-top: 3rem;
  margin-bottom: 0.7rem;
  font-size: 30px;
}
.testimonialsWrapper .heading p {
  width: min(30rem, 90%);
}

@media all and (max-width: 1000px) {
  .admissionsteps-container,
  .curriculum-admission {
    padding: 1.5rem 2rem;
  }

  .admission-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.5rem;
  }
  .curriculum-admission div p,
  .curriculum-admission h2 {
    text-align: start;
  }
  .arrow-hidden {
    display: none;
  }
}
