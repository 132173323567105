.classrooms,
.playAreas,
.computerLab {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 2000px;
  gap: 4rem;
  padding: 64px 112px;
  align-items: center;
  vertical-align: middle;
}

.classrooms .contentTwo,
.playAreas .contentTwo,
.computerLab .contentTwo {
  width: 50%;
  height: 100%;
}
.classrooms .contentTwo img,
.playAreas .contentTwo img,
.computerLab .contentTwo img {
  width: 100%;
  height: 100%;
}
.classrooms .contentOne,
.playAreas .contentOne,
.computerLab .contentOne {
  max-width: 50%;
  background-color: white;
}

.facilities.intro {
  padding: 64px 112px;
  margin: 0;
}

.intro h2 {
  margin-bottom: 24px;
}

.intro p {
  margin-bottom: 32px;
}
.programs.intro h2 {
  margin-bottom: 0;
  font-size: 36px;
  text-align: center;
  line-height: 1.2;
}
.programs.intro p {
  margin-bottom: 0;
  font-size: 18px;
  text-align: center;
}
article.programs.intro button{
  width: 14rem;
  margin-left: auto;
  margin-inline: auto;
}
.intro Button {
  padding: 12px 20px;
  cursor: pointer;
}

.gallerySection {
  padding: 64px 112px;
}
