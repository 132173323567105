.footer-container {
  background-color: var(--primary-blue);
  background-image: url(./assets/Elements.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 1.5rem 2rem;
  position: relative;
}

.principal-content {
  display: flex;
  justify-content: space-between;
  padding: 0 10rem 2rem 10rem;
  color: white;
}

.logo-footer {
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  width: 5rem;
  height: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-footer img {
  border-radius: inherit;
}

.links-footer {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  width: 80%;
}

.links-footer > section {
  max-width: 40%;
}

.links-footer > div {
  display: flex;
  justify-content: space-between;
  min-width: 50%;
}

.links-footer > div > section {
  width: 100%;
}

.section-title-footer {
  font-weight: bolder;
  margin-bottom: 0.5rem;
  width: 100%;
}

.quickLinks-footer a,
.contact-footer a {
  display: block;
  margin-bottom: 0.25rem;
  color: white;
}

.socialmedia-footer {
  min-width: fit-content;
}
.socialmedia-footer a {
  padding: 0 0.25rem;
}

.rights-text {
  color: white;
  text-align: center;
}
.mpcLink{

   text-decoration: none;
   color: white;

}
@media all and (max-width: 1000px) {
  .principal-content {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
    padding: 0;
    margin-bottom: 1rem;
  }

  .links-footer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .links-footer section {
    max-width: 100%;
  }
}
