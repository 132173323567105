.gallery-container {
  padding: 4rem 7rem;
  max-width: 1440px;
  margin: auto;
}

.badge-wrapper-gallery {
  width: 100%;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  gap: 3rem;
}

.badge-wrapper-gallery a {
  background-color: white;
  padding: 0.5rem 1rem;
  color: var(--textColor-primary);
  border: none;
  border-radius: 6px;
  margin: 5px 5px 5px 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.32px;
}

.badge-wrapper-gallery .active-btn {
  background-color: #f5f5f5;
}

.pics-gallery-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 2rem;
}

.pics-gallery-wrapper img {
  max-width: 100%;
}

.gallery-content1 {
  grid-row: span 2;
  grid-column: span 2;
}

.btnGallery {
  width: 100%;
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
}

@media all and (max-width: 1000px) {
  .gallery-container {
    padding: 1.5rem 2rem;
  }

  .badge-wrapper-gallery {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 0.75rem;
  }

  .pics-gallery-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
